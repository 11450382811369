import React from "react"
import promo from "../../src/video/rf-dance-salsa-weds.mp4"
import Hero from "../components/hero"
import Contact from "../components/contact"
import TeamImg from "../images/team.jpeg"
import Layout from '../components/layout-default'

const SalsaWednesdays = () => (
  <Layout
    title="Salsa Wednesdays at RF Dance: Learn & Dance in Orange County"
    description="Experience the best salsa night in Orange County every Wednesday at RF Dance. All levels welcome for fun classes and social dancing. Join us now!" 
    pathname="salsa-wednesdays"
    img={TeamImg}>
    <Hero 
      titleRed="Salsa"
      titleWhite="Wednesdays" 
      subtitle="Orange County's Wednesday night salsa class"
      promo={promo}
      secondaryBtnTxt="Sign up online"
      secondaryBtnLink="/classes"
      secondaryBtnType="link" />

    <div className="content-block">
      <div className="container">
        <div className="columns">
          <div className="column is-two-thirds display--inline-block">
            <h2>Join us Wednesday nights for Orange County's best weekly salsa event!</h2>
            <p><strong>Wednesday nights fire-up at RF Dance!</strong> Our Salsa Wednesday classes always begin with a fun, all-levels warm-up in our main studio room. After our muscles are warm and our mindset is hot, we separate into different studio rooms for beginner, intermediate, and advanced levels.</p>
            <p>After an hour of instruction, all levels gather back into our main studio room where our instructors will review all of the evening’s material and allow participants to film the newly learned salsa movements.</p>
            <p>Our classes end with more salsa music and open floor space where all participants may practice their new moves in a friendly and welcoming social environment. <strong>Salsa Wednesdays</strong> will teach you how to move with sabor!</p>
          </div>
          <div className="column display--inline-block has-text-centered">
            <div className="tile tile-custom is-secondary-custom">
              <p><strong>When:</strong> 8PM - 9:15PM</p>
              <p><strong>Where:</strong> RF Dance Studio<br />1517 N. Main st<br />Santa Ana, CA 92701<br/>(949) 689-1835</p>
              {/* <p><strong>$15</strong> Single Drop-in Class<br /><strong>$65</strong> 5-Class Bundle (save 13%)<br /><strong>$75</strong> Monthly Unlimited (includes Mondays Bachata, Tuesdays Bachata, Wednesdays Salsa, Thursdays Cumbia, Sunday Trainings, Monthly Workshop, and $75 Private Lessons)</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    <Contact />
  </Layout>
)

export default SalsaWednesdays